:root {
  --font: "Avenir", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --rem: 18px;
  --smaller-rem: 16px;
  --smallest-rem: 15px;
  --body-color: #434a54;
  --gray: #767687;
  --content-width: 700px;
  --max-width: 1600px;
  --line-height: 1.6;
  --radius: 1rem;
  --small: 15px;
  --accent: #3333ff;
  --highlight: linear-gradient(45deg, #3333ff22, #ff333322);
  --breakpoint-large: 1200px;
  --breakpoint-small: 950px;
}
