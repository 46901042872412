.project {
  margin-bottom: 5rem;
}

.columns {
  display: flex;
}

@media (max-width: 950px) {
  .columns {
    display: block;
  }
}

.left {
  flex: 0.75;
  flex: 0 0 var(--content-width);
}

.right {
  flex: 1;
}

.project a {
  color: var(--accent);
  font-weight: 500;
  padding-bottom: 4px;
}

.project em {
  font-style: normal;
  background: var(--highlight);
  padding: 2px;
  border-radius: 2px;
}

@media (max-width: 1200px) {
  .project em {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.comingSoon {
  color: var(--gray);
  opacity: 0.5;
}
