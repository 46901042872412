.skills {
  list-style: none;
  line-height: 1.5;
  text-align: right;
  padding-left: 2rem;
}

@media (max-width: 950px) {
  .skills {
    margin-top: 1rem;
    padding-left: 0;
    text-align: left;
  }
}

.column {
  flex: 1;
  padding-left: 2rem;
}

.skills strong {
  font-weight: 500;
  color: #000;
  font-size: var(--small);
}

.skills ul {
  list-style: none;
  margin-bottom: var(--small);
}

.skill {
  color: var(--gray);
  font-size: var(--small);
  display: inline;
}

.skill:after {
  content: ", ";
}

.skill:last-of-type:after {
  content: "";
}
