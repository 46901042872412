.cta {
  color: #ababbc;
  background: #000;
  border-radius: var(--radius);
  padding: 5rem;
}

@media (max-width: 1200px) {
  .cta {
    padding: 2rem;
  }
}

.content {
  max-width: var(--content-width);
}

.cta h2 {
  color: #fff;
}

.cta a {
  color: #fff;
  padding-bottom: 2px;
  border-bottom: 2px solid var(--accent);
  display: inline-block;
}

.cta a:hover {
  border-bottom-color: #ff0080;
}
