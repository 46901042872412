.header {
  display: flex;
  align-items: center;
}

.header a {
  color: var(--gray);
}

a.logo {
  margin-right: 2rem;
}

.header ul {
  list-style: none;
}

.header li {
  display: inline-block;
  margin-right: 1rem;
}

.header img {
  max-width: 100px;
}
