.footer {
  padding: 5rem 0 0;
  font-size: var(--small);
}

.footer ul {
  list-style: none;
}

.footer li {
  display: inline-block;
  margin-right: 2rem;
}

.footer a {
  color: var(--gray);
  transition: 100ms color ease-in-out;
}

.footer a:hover {
  color: #000;
}

@media (max-width: 1200px) {
  .footer {
    padding: 2rem 0 5rem;
  }
}
