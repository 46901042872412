.introduction {
  width: 100%;
  max-width: var(--content-width);
  margin: 5rem 0;
}

.highlight {
  background: var(--highlight);
  padding: 2px;
  border-radius: 2px;
}

@media (max-width: 1200px) {
  .highlight {
    padding-top: 0;
    padding-bottom: 0;
  }
}
