.gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border-radius: var(--radius);
  overflow: hidden;
  position: relative;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11),
    0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11); */
}

@media (max-width: 1200px) {
  .gallery {
    margin-left: -1rem;
    margin-right: -1rem;
    border-radius: 0;
  }
}

.gallery img {
  width: 100%;
  height: auto;
}

.sample {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  font-size: var(--small);
  background: #000;
  padding: 8px 12px;
  border-radius: var(--radius);
}

.sample svg {
  margin-right: 8px;
  fill: yellow;
}

@media (max-width: 1200px) {
  .sample {
    padding: 4px 8px;
    font-size: 10px;
  }

  .sample svg {
    margin-right: 4px;
  }
}
