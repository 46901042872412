* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
}

html {
  font-size: var(--rem);
  font-family: var(--font);
  color: var(--body-color);
  line-height: var(--line-height);
  background: #fff;
}

@media (max-width: 1200px) {
  html {
    font-size: var(--smaller-rem);
  }
}

@media (max-width: 950px) {
  html {
    font-size: var(--smallest-rem);
  }
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  color: #000;
}

h1 {
  font-size: 28px;
  font-weight: 400;
  color: #1a1d20;
  margin-bottom: 3rem;
}

@media (max-width: 1200px) {
  h1 {
    font-size: 20px;
  }
}

h2 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

@media (max-width: 1200px) {
  h2 {
    font-size: 1.1rem;
  }
}

h3 {
  font-weight: 400;
  font-size: 1.25rem;
}

h4 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--gray);
  margin-bottom: 1rem;
  font-weight: 600;
  opacity: 0.5;
}

p {
  margin: 1rem 0;
}
