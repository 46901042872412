.content {
  padding: 100px;
  max-width: var(--max-width);
  width: 100%;
  margin: auto;
}

@media (max-width: 1200px) {
  .content {
    padding: 1rem;
  }
}
