.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
}

.button {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffffcc;
  backdrop-filter: blur(4px);
  border: 0;
  transition: background 100ms ease-in-out;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1rem;
}

.button:hover {
  background: #fff;
}

.buttonContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.left {
  left: 0;
  width: 50%;
}

.right {
  width: 50%;
  right: 0;
}

.arrow svg {
  stroke: #000;
  transition: stroke 100ms ease-in-out;
}

.right .buttonContainer {
  right: 0;
}
