.services {
  background: #0000ff11;
  padding: 5rem;
  border-radius: var(--radius);
  margin-bottom: 5rem;
}

@media (max-width: 1200px) {
  .services {
    padding: 2rem;
    margin: 0 0 2rem;
  }
}

.services h2 {
  margin-bottom: 1rem;
}

.services ul {
  list-style: none;
}

.description {
  margin-bottom: 2rem;
  max-width: var(--content-width);
}

.grid {
  display: flex;
}

.column {
  flex: 1;
}

@media (max-width: 950px) {
  .grid {
    display: block;
  }

  .column {
    margin-bottom: 2rem;
  }

  .column:last-of-type {
    margin-bottom: 0;
  }
}
